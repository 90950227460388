<template>
  <div class="fill-info-content">
    <div class="content">
      <div class="step-count">
        <p
          class="title"
          :style="{
            backgroundImage: `url(${step === 1 ? step1_b : step1_g})`,
            color: step === 1 ? '#fff' : '#4d4d4d'
          }"
        >
          1.上传图片
        </p>
        <p
          class="title"
          :style="{
            backgroundImage: `url(${step === 2 ? step2_b : step2_g})`,
            color: step === 2 ? '#fff' : '#4d4d4d'
          }"
        >
          2.完善信息
        </p>
        <p
          class="title"
          :style="{
            backgroundImage: `url(${step === 3 ? step3_b : step3_g})`,
            color: step === 3 ? '#fff' : '#4d4d4d'
          }"
        >
          3.注册成功
        </p>
      </div>

      <component :is="Components" @handleNextStep="step++"></component>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import Component1 from "./components/Step1";
import Component2 from "./components/Step2";
import Component3 from "./components/Step3";

export default {
  name: "FillInfo",
  components: { Component1, Component2, Component3 },

  setup() {
    const step1_g =
      "https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/client/register/step1_grey.png";
    const step1_b =
      "https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/client/register/step1_blue.png";
    const step2_g =
      "https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/client/register/step2_grey.png";
    const step2_b =
      "https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/client/register/step2_blue.png";
    const step3_g =
      "https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/client/register/step3_grey.png";
    const step3_b =
      "https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/client/register/step3_blue.png";

    const step = ref(3);

    const Components = computed(() => `Component${step.value}`);

    return {
      step,
      Components,
      step1_g,
      step1_b,
      step2_g,
      step2_b,
      step3_g,
      step3_b
    };
  }
};
</script>

<style lang="less" scoped>
.fill-info-content {
  background: #f8f9fb;
  .content {
    width: 898px;
    height: 100vh;
    background: #ffffff;
    margin: 0 auto;
    padding: 40px 160px 104px;
    .step-count {
      display: flex;
      margin-bottom: 32px;
      .title {
        width: 200px;
        height: 40px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        font-size: 16px;
        text-align: center;
        line-height: 40px;
        margin-left: -20px;
        &:nth-child(2) {
          width: 224px;
        }
        &:nth-child(1) {
          margin: 0;
        }
      }
    }
  }
}
</style>
