<template>
  <div class="step3-content">
    <img
      class="mt-100"
      src="https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/client/register/success.png"
    />
    <p class="mt-16 fz-16 fw-600">注册成功，请前往快驰科技增值服务企业端登录</p>
    <a-button class="mt-48" type="primary" @click="handleToClient"
      >前往企业端</a-button
    >
  </div>
</template>

<script>
// import { ref } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "FillInfo",
  components: {},

  setup() {
    const router = useRouter();

    const handleToClient = () => {
      router.push({ name: "Home" });
    };

    return { handleToClient };
  }
};
</script>

<style lang="less" scoped>
.step3-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  img {
    height: 83px;
    width: 94px;
  }
}
</style>
