<template>
  <div class="step1-content">
    <p class="tip mb-32">
      <Icon
        class="mr-4"
        name="iconbiaodanneitishiicon"
        height="16"
        width="16"
      ></Icon
      >上传图片，信息内容将被快速识别录入
    </p>

    <a-form
      layout="inline"
      ref="formRef"
      :rules="rules"
      :label-col="{ style: 'width:130px' }"
      :model="formState"
    >
      <a-form-item
        label="网络货运许可证"
        name="wlhy_license_arr"
        style="width:100%;"
        :wrapper-col="{ style: 'width:calc(100% - 130px)' }"
        required
      >
        <div
          :class="{
            wlhy_license: true,
            isUpload: formState.wlhy_license_arr.length === 1
          }"
        >
          <template
            v-for="(item, index) in formState.wlhy_license_arr"
            :key="index"
          >
            <p-uploader
              :value="item"
              @input="e => WLHY.uploadInputHandle(e, index)"
              :size="{ w: '180px', h: '120px' }"
              class="mx-8 mb-24"
            />
          </template>
          <p-uploader-trigger
            v-show="formState.wlhy_license_arr.length < 1"
            @selected="WLHY.handleSelected"
            :fileSize="8"
            :size="{ w: '180px', h: '120px' }"
            class="mx-8 mb-24"
          />
        </div>
      </a-form-item>
      <a-form-item
        label="营业执照"
        name="business_license_arr"
        style="width:100%;"
        :wrapper-col="{ style: 'width:calc(100% - 130px)' }"
        required
      >
        <div
          :class="{
            business_license: true,
            isUpload: formState.business_license_arr.length === 1
          }"
        >
          <template
            v-for="(item, index) in formState.business_license_arr"
            :key="index"
          >
            <p-uploader
              :value="item"
              @input="e => License.uploadInputHandle(e, index)"
              :size="{ w: '180px', h: '120px' }"
              class="mx-8 mb-24"
            />
          </template>
          <p-uploader-trigger
            v-show="formState.business_license_arr.length < 1"
            @selected="License.handleSelected"
            :fileSize="8"
            :size="{ w: '180px', h: '120px' }"
            class="mx-8 mb-24"
          />
        </div>
      </a-form-item>
      <a-form-item
        label="法人身份证照片"
        style="width:100%;"
        :wrapper-col="{ style: 'width:calc(100% - 130px)' }"
        name="id_card_head"
        required
      >
        <div
          :class="{
            IDtop: true,
            isUpload: formState.id_card_head_arr.length === 1
          }"
        >
          <template
            v-for="(item, index) in formState.id_card_head_arr"
            :key="index"
          >
            <p-uploader
              :value="item"
              @input="e => Front.uploadInputHandle(e, index)"
              :size="{ w: '180px', h: '120px' }"
              class="mx-8 mb-24"
            />
          </template>
          <p-uploader-trigger
            v-show="formState.id_card_head_arr.length < 1"
            @selected="Front.handleSelected"
            :fileSize="8"
            :size="{ w: '180px', h: '120px' }"
            class="mx-8 mb-24"
          />
        </div>
        <div
          :class="{
            IDbtm: true,
            isUpload: formState.id_card_national_emblem_arr.length === 1
          }"
        >
          <template
            v-for="(item, index) in formState.id_card_national_emblem_arr"
            :key="index"
          >
            <p-uploader
              :value="item"
              @input="e => Back.uploadInputHandle(e, index)"
              :size="{ w: '180px', h: '120px' }"
              class="mx-8 mb-24"
            />
          </template>
          <p-uploader-trigger
            v-show="formState.id_card_national_emblem_arr.length < 1"
            @selected="Back.handleSelected"
            :fileSize="8"
            :size="{ w: '180px', h: '120px' }"
            class="mx-8 mb-24"
          />
        </div>
      </a-form-item>

      <a-form-item
        class="mt-40"
        style="width:100%;"
        :wrapper-col="{ span: 8, offset: 10 }"
      >
        <a-button type="primary" @click="handleSubmit">
          下一步
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useBatchUpload } from "@/hooks/UseBatchUpload";
import { getInvoiceRules } from "./contants/utils";

export default {
  name: "FillInfo",
  components: {},

  setup(props, ctx) {
    const formState = ref({
      wlhy_license: {}, //营业执照
      wlhy_license_arr: [], //营业执照
      business_license: {}, //营业执照
      business_license_arr: [], //营业执照
      id_card_head: {}, //身份证正面
      id_card_head_arr: [], //身份证正面
      id_card_national_emblem: {}, //身份证反面
      id_card_national_emblem_arr: [] //身份证反面
    });

    const formRef = ref();

    // 营业执照
    const WLHY = useBatchUpload(formState, "wlhy_license_arr");
    // 营业执照
    const License = useBatchUpload(formState, "business_license_arr");
    //IDcard
    const Front = useBatchUpload(formState, "id_card_head_arr");
    //IDcard
    const Back = useBatchUpload(formState, "id_card_national_emblem_arr");

    watch(
      () => formState.value.id_card_national_emblem_arr,
      (newValue, oldValue) => {
        let oldLen = oldValue.length;
        let newLen = newValue.length;
        if (oldLen === 0 && newLen === 1) {
          formRef.value.validate(["id_card_head"]);
        }
      }
    );

    //身份证上传验证规则
    let validateIdCardHead = () => {
      let idCardFrontArr = formState.value.id_card_head_arr;
      let idCardBackArr = formState.value.id_card_national_emblem_arr;
      if (idCardFrontArr.length === 0) {
        return Promise.reject("请上传身份证正面");
      } else if (idCardBackArr.length === 0) {
        return Promise.reject("请上传身份证反面");
      }
      return Promise.resolve();
    };

    const rules = getInvoiceRules(validateIdCardHead);

    const handleSubmit = () => {
      formState.value.business_license =
        formState.value.business_license_arr[0] || {};
      formState.value.id_card_head = formState.value.id_card_head_arr[0] || {};
      formState.value.id_card_national_emblem =
        formState.value.id_card_national_emblem_arr[0] || {};

      formRef.value.validate().then(() => {
        console.log(formState.value);
        ctx.emit("handleNextStep");
      });
    };

    return {
      formState,
      WLHY,
      License,
      Front,
      Back,
      rules,
      formRef,
      handleSubmit
    };
  }
};
</script>

<style lang="less" scoped>
.step1-content {
  .tip {
    color: #808080;
    font-size: 12px;
    display: flex;
    align-items: center;
  }
}

/* 上传样式调整 */
.IDtop,
.IDbtm {
  display: inline-block;
  vertical-align: top;
  line-height: 0;
}
:deep(.ant-upload.ant-upload-select-picture-card) {
  width: 100%;
  height: 100%;
}
// 图片宽高
:deep(.image-show) {
  max-height: 102px;
  max-width: 162px;
}
:deep(.ant-upload-text) {
  display: none;
}
.wlhy_license {
  line-height: 0;
  & :deep(.ant-upload.ant-upload-select-picture-card) {
    background: url("http://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/admin/public/road-transport-license.png")
      center/cover;
    margin-bottom: 0;
    height: 100%;
    width: 100%;
  }
}
.business_license {
  line-height: 0;
  & :deep(.ant-upload.ant-upload-select-picture-card) {
    background: url("http://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/admin/public/business_license_upload.png")
      center/cover;
    margin-bottom: 0;
    height: 100%;
    width: 100%;
  }
}
.IDtop :deep(.ant-upload.ant-upload-select-picture-card) {
  background: url("http://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/admin/public/idcard-front-upload.png")
    center/cover;
  margin-bottom: 0;
  height: 100%;
  width: 100%;
}
.IDbtm :deep(.ant-upload.ant-upload-select-picture-card) {
  background: url("http://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/admin/public/idcard-back-upload.png")
    center/cover;
  margin-bottom: 0;
  height: 100%;
  width: 100%;
}
:deep(.ant-upload.ant-upload-select-picture-card) {
  overflow: hidden;
  position: relative;
  &:hover {
    .shadow-bg {
      display: flex;
    }
    .delete-icon {
      display: block;
    }
  }
  .delete-icon {
    display: none;
    background: #3d7fff;
    color: white;
    position: absolute;
    right: -16px;
    top: -16px;
    width: 32px;
    height: 32px;
    line-height: 44px;
    text-align: left;
    padding-left: 4px;
    font-size: 16px;
    border-radius: 50%;
    z-index: 2;
  }
  .shadow-bg {
    background-color: rgba(0, 0, 0, 0.25);
    display: none;
    flex-direction: column;
    justify-content: center;
    color: white;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
    .anticon-eye-o {
      color: white;
    }
  }
}
</style>
