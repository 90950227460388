export const getInvoiceRules = validateIdCardHead => {
  const rules = {
    wlhy_license_arr: [
      {
        required: true,
        message: "网络货运许可证不能为空"
      }
    ],
    business_license_arr: [
      {
        required: true,
        message: "营业执照不能为空"
      }
    ],
    id_card_head: [
      {
        required: true,
        validator: validateIdCardHead
      }
    ]
  };

  return rules;
};
