<template>
  <div class="step2-content">
    <a-form
      ref="formRef"
      :model="formState"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-item label="企业名称" name="name">
        <a-input v-model:value="formState.company_name" placeholder="请输入" />
      </a-form-item>
      <a-form-item label="统一社会信用代码" name="mobile">
        <a-input v-model:value="formState.company_name" placeholder="请输入" />
      </a-form-item>
      <a-form-item label="企业地址" name="address">
        <p-cascader
          placeholder="请选择省/市/区"
          v-model:value="formState"
          @change="handleCityCheck"
        />
      </a-form-item>
      <a-form-item label="详细地址" name="identity_no">
        <a-input v-model:value="formState.company_name" placeholder="请输入" />
      </a-form-item>
      <a-form-item label="入驻产业园" name="sp_code">
        <a-select v-model:value="formState.company_name" placeholder="请选择">
          <a-select-option value="ZHAOYOU">找油网</a-select-option>
          <a-select-option value="NUCARF">万金油</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="企业电话" name="identity_no">
        <a-input v-model:value="formState.company_name" placeholder="请输入" />
      </a-form-item>
      <a-form-item label="开户银行" name="identity_no">
        <a-input v-model:value="formState.company_name" placeholder="请输入" />
      </a-form-item>
      <a-form-item label="银行账号" name="identity_no">
        <a-input v-model:value="formState.company_name" placeholder="请输入" />
      </a-form-item>
      <a-form-item label="企业联系人" name="identity_no">
        <a-input v-model:value="formState.company_name" placeholder="请输入" />
      </a-form-item>
      <a-form-item label="联系人号码" name="identity_no">
        <a-input v-model:value="formState.company_name" placeholder="请输入" />
      </a-form-item>
      <a-form-item label="短信验证码" name="identity_no">
        <a-input v-model:value="formState.company_name" placeholder="请输入" />
      </a-form-item>
      <a-form-item label="法人姓名" name="identity_no">
        <a-input v-model:value="formState.company_name" placeholder="请输入" />
      </a-form-item>
      <a-form-item label="法人身份证号" name="identity_no">
        <a-input v-model:value="formState.company_name" placeholder="请输入" />
      </a-form-item>

      <a-form-item
        class="mt-40"
        style="width:100%;"
        :wrapper-col="{ span: 8, offset: 10 }"
      >
        <a-button type="primary" @click="handleSubmit">
          下一步
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "FillInfo",
  components: {},

  setup(_, ctx) {
    const formState = ref({
      company_name: undefined,
      province: "", //省
      city: "", //市
      area: "" //区
    });

    // 省市区选择
    const handleCityCheck = value => {
      formState.value.address = value; //省
      formState.value.province = value[0]; //省
      formState.value.city = value[1]; //市
      formState.value.area = value[2]; //区
    };

    const formRef = ref();

    const handleSubmit = () => {
      formRef.value.validate().then(() => {
        console.log(formState.value);
        ctx.emit("handleNextStep");
      });
    };

    return {
      formState,
      labelCol: { span: 8 },
      wrapperCol: { span: 13 },
      handleCityCheck,
      handleSubmit
    };
  }
};
</script>

<style lang="less" scoped></style>
